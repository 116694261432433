import { css } from '@emotion/react'
import {
  ComponentPropsWithRef,
  Dispatch,
  SetStateAction,
  forwardRef,
} from 'react'
import { UseSiteSearchData } from 'react-datocms/use-site-search'

import { useMainNavContext } from '@/features/layout'
import { bezier, mq } from '@/theme/mixins'

import { useSearchContext } from '../../contexts/searchContext'
import { SearchField } from '../SearchField/SearchField'
import { SearchIcon } from './SearchIcon'

interface Props extends ComponentPropsWithRef<'div'> {
  onDataUpdate: Dispatch<SetStateAction<UseSiteSearchData | undefined>>
}

export const SearchButton = forwardRef<HTMLDivElement, Props>(
  ({ onDataUpdate, ...props }, ref): JSX.Element => {
    const { activeNavItemId, setActiveNavItemId } = useMainNavContext()
    const { searchIsOpen, setSearchIsOpen } = useSearchContext()
    const styles = {
      container: css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        /* height: calc(var(--fs-48) * 1.25); */
        margin-right: -1rem;
      `,
      button: css`
        position: relative;
        transition: opacity 200ms ease;
        padding: 1rem;
        pointer-events: all;
        ${searchIsOpen &&
        css`
          opacity: 0;
          pointer-events: none;
        `};
      `,
      icon: css`
        color: #fff;
        width: 1.5em;
        height: 1.5em;
        transition: transform 500ms ${bezier.bounce};
        @media (hover: hover) {
          button:hover & {
            transform: scale3d(1.1, 1.1, 1);
          }
        }
      `,
      searchField: css`
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        color: #fff;
        transition:
          width 400ms ${bezier.easeOut},
          opacity 200ms ease;
        font-size: var(--fs-48);
        font-family: var(--ff-display);
        overflow: visible;
        ${searchIsOpen &&
        css`
          opacity: 1;
          width: calc(50vw - var(--margin));
          ${mq().s} {
            width: calc(100vw - 2 * var(--margin) + 1rem);
          }
        `};
      `,
    }
    return (
      <div
        css={styles.container}
        ref={ref}
        {...props}
      >
        <SearchField
          css={styles.searchField}
          onDataUpdate={onDataUpdate}
        />
        <button
          css={styles.button}
          aria-label="Search"
          onClick={() => {
            setSearchIsOpen(true)
            // window.scrollTo(0, 0)
            activeNavItemId && setActiveNavItemId(null)
          }}
          tabIndex={searchIsOpen ? -1 : 0}
        >
          <SearchIcon css={styles.icon} />
        </button>
      </div>
    )
  }
)

SearchButton.displayName = 'SearchButton'
